<template>
  <el-form label-width="80px">
    <el-row style="padding-bottom: 20px">
      <el-col :span="24" align="center" class="font1">
        <!-- 接近远离状态 -->
        {{ radarStatus.approachKeepState }}
      </el-col>
    </el-row>
    <el-row>
      <el-col align="center" :span="24">
        <!--  环境状态 -->
        <div class="circular"></div>
        <div class="location">{{ radarStatus.environmentalState }}</div>
      </el-col>
    </el-row>
    <el-row style="padding: 20px 0 40px 0px">
      <el-col :span="24" align="center" class="font2">
        <!-- 运动状态/跌倒状态/驻留时间 -->
        {{ radarStatus.motionState }}
        {{ radarStatus.tumbleState }}
        {{ radarStatus.dwellTime == "无" ? "" : radarStatus.dwellTime }}
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="9" align="right">
        <el-button type="info" @click="setUpBtn">设置</el-button>
      </el-col>
      <el-col :span="6" align="center">
        <el-button type="info" @click="recordBtn">记录</el-button>
      </el-col>
      <el-col :span="9" align="left">
        <el-button type="info" @click="locationBtn">定位</el-button>
      </el-col>
    </el-row>
    <el-dialog
      title="设置"
      :visible.sync="radarVisible"
      width="width"
      append-to-body
    >
      <el-row style="padding-bottom: 20px">
        <el-col :span="6">
          设备开关
          <el-select
            v-model="setRadarSensor.deviceSwitch"
            @change="deviceSwitchChange"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option
              v-for="item in switchOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          灵敏度
          <el-select
            clearable
            v-model="setRadarSensor.humanSensitivity"
            @change="humanSensitivityChange"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option
              v-for="item in deviceSensitivity"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          安装场景
          <el-select
            clearable
            v-model="setRadarSensor.systemScenario"
            @change="systemScenarioChange"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option
              v-for="item in deviceSwitchOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row style="padding-bottom: 20px">
        <el-col :span="6">
          跌倒开关
          <el-select
            clearable
            v-model="setRadarSensor.fallSwitch"
            @change="switchChange"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option
              v-for="item in switchOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          灵敏度
          <el-select
            clearable
            v-model="setRadarSensor.sensitivity2"
            @change="devSensitivityChange"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option
              v-for="item in deviceSensitivity"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          报警时间
          <el-select
            clearable
            v-model="setRadarSensor.sensitivity3"
            @change="fallSensitivityChange"
            placeholder="请选择"
            style="width: 100px"
          >
            <el-option
              v-for="item in fallAlarm"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          没动作为跌倒报警。
        </el-col>
      </el-row>
      <el-row>
        <el-col style="padding: 10px 0px 10px 0px">情景设置：</el-col>
        <el-form inline>
          <el-form-item
            :label="item.name"
            v-for="item in list"
            :key="item.cpKeyValue"
          >
            <el-select
              placeholder="未设置"
              clearable
              v-model="item.selectItem"
              style="width: 100px"
              @change="changeScenarioSetting(item.cpKeyValue, item.selectItem)"
            >
              <el-option
                :label="val.label"
                :value="val"
                v-for="val in item.select"
                :key="val.key"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-row>
      <div slot="footer">
        <el-button @click="radarVisible = false">取 消</el-button>
        <el-button type="primary" @click="preservationBtn">保 存</el-button>
      </div>
    </el-dialog>
    <el-dialog
      title="上报记录"
      :visible.sync="recordVisible"
      width="width"
      append-to-body
    >
      <el-table
        :data="recordData"
        style="width: 100%"
        border
        height="250px"
        v-loading="loading1"
      >
        <el-table-column prop="saDeviceSn" label="SN码" width="width">
        </el-table-column>
        <el-table-column prop="saWarningStatus" label="报警状态" width="width">
        </el-table-column>
        <el-table-column prop="saHandleStatus" label="处理状态" width="width">
        </el-table-column>
        <el-table-column prop="saReportingTime" label="上报时间" width="width">
        </el-table-column>
      </el-table>
    </el-dialog>
  </el-form>
</template>
<script>
import {
  linkageGetLinkageScene,
  sceneLinkageSave,
  sceneLinkageInfo,
  locationControl,
} from "@/api/scene.js";
const linkeSceneList = [
  {
    name: "有人执行",
    // spdId: "",
    // disabled: true,
    cpKeyValue: 1,
    selectItem: "",
  },
  {
    name: "无人执行",
    // spdId: "",
    // disabled: true,
    cpKeyValue: 2,
    selectItem: "",
  },
  {
    name: "跌倒执行",
    // spdId: "",
    // disabled: true,
    cpKeyValue: 3,
    selectItem: "",
  },
];
export default {
  name: "RadarSensor",
  props: {
    row: {
      type: Object | String,
    },
    radarStatus: {
      type: Object | String,
    },
  },
  data() {
    return {
      list: JSON.parse(JSON.stringify(linkeSceneList)),
      radarVisible: false,
      recordVisible: false,
      recordData: [],
      setRadarSensor: {
        deviceSwitch: "",
        humanSensitivity: "",
        systemScenario: "",
        fallSwitch: "",
        sensitivity2: "",
        sensitivity3: "",
      },
      switchOptions: [
        {
          value: "1",
          label: "启用",
        },
        {
          value: "0",
          label: "停用",
        },
      ],
      deviceSensitivity: [
        {
          value: "1",
          label: "1级",
        },
        {
          value: "2",
          label: "2级",
        },
        {
          value: "3",
          label: "3级",
        },
        {
          value: "4",
          label: "4级",
        },
        {
          value: "5",
          label: "5级",
        },
        {
          value: "6",
          label: "6级",
        },
        {
          value: "7",
          label: "7级",
        },
        {
          value: "8",
          label: "8级",
        },
        {
          value: "9",
          label: "9级",
        },
        {
          value: "10",
          label: "10级",
        },
      ],
      fallAlarm: [
        {
          value: "0",
          label: "1分钟",
        },
        {
          value: "1",
          label: "2分钟",
        },
        {
          value: "2",
          label: "3分钟",
        },
        {
          value: "3",
          label: "4分钟",
        },
        {
          value: "4",
          label: "5分钟",
        },
        {
          value: "5",
          label: "6分钟",
        },
        {
          value: "6",
          label: "7分钟",
        },
        {
          value: "7",
          label: "8分钟",
        },
        {
          value: "8",
          label: "9分钟",
        },
        {
          value: "9",
          label: "10分钟",
        },
      ],
      deviceSwitchOptions: [
        {
          value: "0",
          label: "默认模式",
        },
        {
          value: "1",
          label: "区域探测模式",
        },
        {
          value: "2",
          label: "卫生间模式",
        },
        {
          value: "3",
          label: "卧室模式",
        },
        {
          value: "4",
          label: "客厅模式",
        },
        {
          value: "5",
          label: "办公室模式",
        },
        {
          value: "6",
          label: "酒店模式",
        },
      ],
      loading1: true,
      someOneScene: "",
      nobodyScene: "",
      fallScene: "",
    };
  },
  methods: {
    changeScenarioSetting(key, value) {
      let { jsId } = value;
      if (key == 1) {
        this.someOneScene = jsId;
      } else if (key == 2) {
        this.nobodyScene = jsId;
      } else {
        this.fallScene = jsId;
      }
    },
    setUpBtn() {
      this.radarVisible = true;
      this.radarSensorQuery();
      // this.sceneSettings();
    },
    radarControl() {
      this.$axios({
        method: "post",
        url: "/common2/devices/operate",
        data: {
          devId: this.row.jourDeviceId,
          radarSwitch: this.setRadarSensor.deviceSwitch,
          humanExistSensitivity: this.setRadarSensor.humanSensitivity
            ? this.setRadarSensor.humanSensitivity
            : "",
          sceneSet: this.setRadarSensor.systemScenario
            ? this.setRadarSensor.systemScenario
            : "",
          fallSwitch: this.setRadarSensor.fallSwitch
            ? this.setRadarSensor.fallSwitch
            : "",
          fallSensitivity: this.setRadarSensor.sensitivity2
            ? this.setRadarSensor.sensitivity2
            : "",
          fallAlarmTime: this.setRadarSensor.sensitivity3
            ? this.setRadarSensor.sensitivity3
            : "",
          someOneScene: this.someOneScene,
          nobodyScene: this.nobodyScene,
          fallScene: this.fallScene,
        },
      })
        .then((res) => {
          this.$message.success("设置成功");
          setTimeout(() => {
            this.radarVisible = false;
          }, 2000);
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    radarSensorQuery() {
      this.$axios({
        url: "common2/devices/getCustomPram",
        method: "get",
        params: {
          devId: this.row.jourDeviceId,
        },
      }).then((res) => {
        let result = res.data.body;
        console.log(result);
        this.$set(
          this.setRadarSensor,
          "deviceSwitch",
          result.radarSwitch.toString()
        );
        this.$set(
          this.setRadarSensor,
          "humanSensitivity",
          result.humanExistSensitivity.toString()
        );
        this.$set(
          this.setRadarSensor,
          "systemScenario",
          result.sceneSet.toString()
        );
        this.$set(
          this.setRadarSensor,
          "fallSwitch",
          result.fallSwitch.toString()
        );
        this.$set(
          this.setRadarSensor,
          "sensitivity2",
          result.fallSensitivity.toString()
        );
        this.$set(
          this.setRadarSensor,
          "sensitivity3",
          result.fallAlarmTime.toString()
        );
        this.someOneScene = result.someOneScene;
        this.nobodyScene = result.nobodyScene;
        this.fallScene = result.fallScene;
        for (let i = 0; i < this.list.length; i++) {
          for (let j = 0; j < this.list[i].select.length; j++) {
            if (this.list[i].cpKeyValue == 1) {
              if (result.someOneScene == this.list[i].select[j].jsId) {
                this.$set(this.list[i], "selectItem", this.list[i].select[j]);
              }
            } else if (this.list[i].cpKeyValue == 2) {
              if (result.nobodyScene == this.list[i].select[j].jsId) {
                this.$set(this.list[i], "selectItem", this.list[i].select[j]);
              }
            } else {
              if (result.fallScene == this.list[i].select[j].jsId) {
                this.$set(this.list[i], "selectItem", this.list[i].select[j]);
              }
            }
          }
        }
      });
    },
    profileQuery() {
      linkageGetLinkageScene({
        hsIds: [this.row.hsId], // 房间ID集合
        sceneAddress: "", // 场景位置
        sceneName: "", // 情景名称
      })
        .then((res) => {
          let result = res.data.result;
          // console.log(result, "321");
          let select = result.map((item) => {
            return {
              ...item,
              key: item.jsSceneCategoryId,
              label: item.jscName,
              value: item.jsSceneCategoryId,
              jsId: item.jsId,
            };
          });
          this.list = this.list.map((item) => {
            return {
              ...item,
              select,
            };
          });
          console.log(this.list, "list-8888");
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    preservationBtn() {
      this.radarControl();
      // let arr = this.list.map((item) => {
      //   return {
      //     jscName: item.name, // 联动名称
      //     jslAisle: item.cpKeyValue, // 设备通道
      //     jslHouseId: this.row.hsId, // 房间id
      //     jslSceneCategory: item.selectItem.value
      //       ? item.selectItem.value
      //       : null, // 情景分类
      //     jslTriggerDevice: this.row.jourDeviceId, // 触发设备
      //   };
      // });
      // sceneLinkageSave(arr)
      //   .then((res) => {
      //     this.$message.success("配对成功");
      //     this.sceneSettings();
      //   })
      //   .catch((err) => {
      //     this.isError(err, this);
      //   });
    },
    // sceneSettings() {
    //   sceneLinkageInfo({
    //     hsId: this.row.hsId,
    //     deviceId: this.row.jourDeviceId,
    //   })
    //     .then((res) => {
    //       let result = res.data.result;
    //       console.log(result, "111");
    //       for (let i = 0; i < result.length; i++) {
    //         for (let j = 0; j < this.list.length; j++) {
    //           if (result[i].jslAisle == this.list[j].cpKeyValue) {
    //             this.$set(this.list[j], "selectItem", {
    //               value: result[i].jslSceneCategory,
    //             });
    //             break;
    //           }
    //         }
    //       }
    //     })
    //     .catch((err) => {
    //       this.isError(err, this);
    //     });
    // },
    deviceSwitchChange(val) {
      this.setRadarSensor.deviceSwitch = val;
    },
    humanSensitivityChange(val) {
      this.setRadarSensor.humanSensitivity = val;
    },
    systemScenarioChange(val) {
      this.setRadarSensor.systemScenario = val;
    },
    switchChange(val) {
      this.setRadarSensor.fallSwitch = val;
    },
    devSensitivityChange(val) {
      this.setRadarSensor.sensitivity2 = val;
    },
    fallSensitivityChange(val) {
      this.setRadarSensor.sensitivity3 = val;
    },
    locationBtn() {
      locationControl({ devId: this.row.jourDeviceId })
        .then((res) => {
          if (res.data.status == 200) {
            this.$message.success("定位成功！");
          }
        })
        .catch((err) => {
          this.isError(err, this);
        });
    },
    recordBtn() {
      this.recordVisible = true;
      this.recordQuery();
    },
    recordQuery() {
      this.$axios({
        method: "GET",
        url: "/common2/smokeAlertEvents",
        params: {
          sn: this.row.devSn,
        },
      })
        .then((res) => {
          this.loading1 = false;
          this.recordData = res.data.result;
        })
        .catch((err) => {
          this.loading1 = false;
          this.isError(err, this);
        });
    },
  },
  created() {
    this.profileQuery();
  },
};
</script>
<style scoped lang='scss'>
.circular {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: rgb(99, 207, 80);
  position: relative;
}
.font1 {
  font-weight: bold;
}
.font2 {
  font-weight: bold;
  color: #7ed321;
}
.location {
  position: absolute;
  left: 320px;
  top: 40px;
  color: #fff;
  font-weight: bold;
  text-align: center;
}
.hint {
  font-weight: bold;
  color: #7ed321;
}
</style>